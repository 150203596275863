import React, { Fragment } from "react"
import { ThemeProvider } from "styled-components"
import { Reset } from "styled-reset"
import { Normalize } from "styled-normalize"
import { parseTheme } from "@sqymagma/theme"
import { GlobalStyles } from "./src/themes/Global"
import mainTheme from "./src/themes/theme"

const defaultTheme = parseTheme({ ...mainTheme })

export const wrapPageElement = ({ element }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Fragment>
        <Reset />
        <Normalize />
        <GlobalStyles />
        {element}
      </Fragment>
    </ThemeProvider>
  )
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { state } = location
  if (state.preserveScroll) {
    return false
  }
}
