const theme = {
  keyColor01: "#5C89D6",
  keyColor02: "#132B53",
  keyColor03: "#AFD1E9",
  keyColor04: "#ffffff",
  keyColor05: "#BEA382",

  neutral01: "#ffffff",
  neutral02: "#FAFAFA",
  neutral03: "#EFF0F1",
  neutral04: "#CED3DB",
  neutral05: "#A3AFC2",
  neutral06: "#667999",
  neutral07: "#3D495C",
  neutral08: "#29303D",
  neutral09: "#181D25",

  primary01: "#F7F9FD",
  primary02: "#E8EDF7",
  primary03: "#C1D1EB",
  primary04: "#95B2E4",
  primary05: "#5C89D6",
  primary06: "#2F66C4",
  primary07: "#0E3981",
  primary08: "#132B53",
  primary09: "#0D1A30",

  secondary01: "#F7FAFD",
  secondary02: "#E5F2FB",
  secondary03: "#AFD1E9",
  secondary04: "#87BADE",
  secondary05: "#5FA2D3",
  secondary06: "#408ABF",
  secondary07: "#215478",
  secondary08: "#163850",
  secondary09: "#0D2130",

  tertiary01: "#FCFAF8",
  tertiary02: "#F7F3EE",
  tertiary03: "#EBE2D6",
  tertiary04: "#DECEBA",
  tertiary05: "#BEA382",
  tertiary06: "#B79367",
  tertiary07: "#83653F",
  tertiary08: "#5A452B",
  tertiary09: "#42392E",

  veil01: { value: "#0D1A30", opacity: 0.3 },
  veil02: { value: "#0D1A30", opacity: 0.08 },
}

module.exports = {
  id: "light",
  fontFamily: {
    fontFamilies: {
      primary: '"Source Serif Pro", sans-serif',
      secondary: "'Work Sans', sans-serif",
    },
  },
  lineHeightBase: "150%",
  color: {
    description: "Observatorio Colors Theme File",
    colors: {
      primaryBackground: { value: theme.keyColor04 },

      brand01: { value: theme.keyColor01 },
      brand02: { value: theme.keyColor02 },
      brand03: { value: theme.keyColor03 },
      brand04: { value: theme.keyColor04 },
      brand05: { value: theme.keyColor05 },

      ui01: { value: theme.keyColor05 },
      ui02: { value: theme.keyColor04 },
      ui03: { value: theme.neutral03 },
      ui04: { value: theme.secondary03 },
      ui05: { value: theme.primary05 },

      line01: { value: theme.primary02 },
      line02: { value: theme.neutral06 },
      line03: { value: theme.neutral03 },

      overlay01: { value: theme.veil01 },
      overlay02: { value: theme.veil02 },

      text01: { value: theme.primary08 },
      text02: { value: theme.primary06 },
      text03: { value: theme.neutral06 },
      text04: { value: theme.neutral05 },
      text05: { value: theme.neutral08 },
      text06: { value: theme.secondary06 },

      interactive01: { value: theme.tertiary06 },
      interactive01Hover: { value: theme.tertiary05 },
      interactive01Focus: { value: theme.secondary06 },
      interactive01Active: { value: theme.tertiary05 },
      textOnInteractive01: { value: theme.primary08 },
      textOnInteractive01Hover: { value: theme.primary09 },
      textOnInteractive01Active: { value: theme.primary09 },

      link01: { value: theme.primary08 },
      link01Hover: { value: theme.primary06 },
      link01Active: { value: theme.primary06 },

      disabled01: { value: theme.neutral06 },
      disabled02: { value: theme.neutral07 },

      icon01: { value: theme.primary06 },
      icon02: { value: theme.tertiary05 },

      textOnInverse01: { value: theme.neutral01 },
      textOnInverse02: { value: theme.secondary05 },
      textOnInverse03: { value: theme.neutral03 },
      textOnInverse04: { value: theme.secondary04 },

      inverse01: { value: theme.primary07 },
      inverse02: { value: theme.primary09 },

      support01: { value: "#66D18C" },
      support02: { value: "#FF824D" },

      instagram: { value: "#ffffff" },
      twitter: { value: "#00ACEE" },
      linkedin: { value: "#2867B2" },
      medium: { value: "#ffffff" },
    },
  },

  gradient: {
    gradients: {
      bannerBackground: {
        direction: "to left",
        stops: [
          {
            color: theme.primary09,
            opacity: 1.0,
            position: "-40%",
          },
          {
            color: theme.primary07,
            opacity: 1.0,
            position: "100%",
          },
        ],
      },
      headerOverlay: {
        direction: "to bottom",
        stops: [
          {
            color: theme.neutral09,
            opacity: 0.8,
            position: "0%",
          },
          {
            color: theme.neutral09,
            opacity: 0,
            position: "100%",
          },
        ],
      },
      splitBackground: {
        direction: "to bottom",
        stops: [
          {
            color: theme.neutral01,
            opacity: 1.0,
            position: "0%",
          },
          {
            color: theme.neutral01,
            opacity: 1,
            position: "50%",
          },
          {
            color: theme.neutral03,
            opacity: 1.0,
            position: "50%",
          },
          {
            color: theme.neutral03,
            opacity: 1,
            position: "100%",
          },
        ],
      },
      splitBackgroundInverse: {
        direction: "to top",
        stops: [
          {
            color: theme.neutral01,
            opacity: 1.0,
            position: "0%",
          },
          {
            color: theme.neutral01,
            opacity: 1,
            position: "50%",
          },
          {
            color: theme.neutral03,
            opacity: 1.0,
            position: "50%",
          },
          {
            color: theme.neutral03,
            opacity: 1,
            position: "100%",
          },
        ],
      },
    },
  },

  mediaquery: {
    mediaqueries: [
      {
        label: "default",
        minWidth: null,
        container: "calc(100% - 48px)",
      },
      {
        label: "xs",
        minWidth: "375px",
        container: "calc(100% - 48px)",
      },
      {
        label: "s",
        minWidth: "768px",
        container: "calc(100% - 144px)",
      },
      {
        label: "m",
        minWidth: "1024px",
        container: "calc(100% - 144px)",
      },
      {
        label: "l",
        minWidth: "1280px",
        container: "1124px",
      },
      {
        label: "xl",
        minWidth: "1400px",
        container: "1280px",
      },
      {
        label: "xxl",
        minWidth: "1920px",
        container: "1486px",
      },
    ],
  },

  textStyle: {
    defaultStyles: {
      fontFamily: "primary",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "140%",
      letterSpacing: "0px",
    },
    textStyles: [
      {
        name: "display01",
        fontFamily: "primary",
        fontSize: "34px",
        lineHeight: "110%",
        fontWeight: "700",
        responsive: [
          {
            breakpoint: "s",
            fontSize: "50px",
            letterSpacing: "0px",
          },
          {
            breakpoint: "m",
            fontSize: "50px",
          },
          {
            breakpoint: "l",
            fontSize: "72px",
            letterSpacing: "-.5px",
          },
          {
            breakpoint: "xxl",
            fontSize: "88px",
          },
        ],
      },
      {
        name: "display02",
        fontFamily: "primary",
        fontSize: "32px",
        lineHeight: "120%",
        fontWeight: "700",
        responsive: [
          {
            breakpoint: "s",
            fontSize: "37px",
            lineHeight: "110%",
          },
          {
            breakpoint: "m",
            fontSize: "48px",
            lineHeight: "110%",
          },
          {
            breakpoint: "l",
            fontSize: "64px",
            letterSpacing: "-.5px",
          },
          {
            breakpoint: "xxl",
            fontSize: "64px",
          },
        ],
      },
      {
        name: "title01",
        fontFamily: "primary",
        fontSize: "28px",
        lineHeight: "120%",
        fontWeight: "700",
        responsive: [
          {
            breakpoint: "s",
            fontSize: "36px",
            lineHeight: "110%",
          },
          {
            breakpoint: "m",
            fontSize: "36px",
            lineHeight: "110%",
          },
          {
            breakpoint: "l",
            fontSize: "48px",
          },
          {
            breakpoint: "xxl",
            fontSize: "64px",
          },
        ],
      },
      {
        name: "title02",
        fontFamily: "primary",
        fontSize: "28px",
        lineHeight: "115%",
        fontWeight: "700",
        responsive: [
          {
            breakpoint: "xs",
            fontSize: "36px",
            lineHeight: "115%",
          },
          {
            breakpoint: "m",
            fontSize: "32px",
          },
          {
            breakpoint: "l",
            fontSize: "36px",
            lineHeight: "115%",
          },
          {
            breakpoint: "xl",
            fontSize: "45px",
          },
          {
            breakpoint: "xxl",
            fontSize: "52px",
          },
        ],
      },
      {
        name: "title03",
        fontFamily: "secondary",
        fontSize: "16px",
        lineHeight: "120%",
        fontWeight: "600",
        responsive: [
          {
            breakpoint: "xs",
            fontSize: "18px",
            fontFamily: "secondary",
            lineHeight: "130%",
          },
          {
            breakpoint: "s",
            fontSize: "21px",
            fontFamily: "secondary",
          },
          {
            breakpoint: "m",
            fontSize: "26px",
            fontFamily: "primary",
            fontWeight: "700",
          },
          {
            breakpoint: "l",
            fontSize: "28px",
            lineHeight: "120%",
          },
          {
            breakpoint: "xl",
            fontSize: "32px",
            lineHeight: "120%",
          },
        ],
      },
      {
        name: "title04",
        fontFamily: "primary",
        fontSize: "20px",
        lineHeight: "125%",
        fontWeight: "600",
        responsive: [
          {
            breakpoint: "s",
            fontSize: "24px",
          },
          {
            breakpoint: "m",
            fontSize: "24px",
          },
          {
            breakpoint: "l",
            fontSize: "24px",
          },
          {
            breakpoint: "xl",
            fontSize: "28px",
          },
        ],
      },
      {
        name: "title05",
        fontSize: "20px",
        lineHeight: "130%",
        fontWeight: "700",
        responsive: [
          {
            breakpoint: "s",
            fontSize: "20px",
          },
          {
            breakpoint: "m",
            fontSize: "20px",
          },
          {
            breakpoint: "l",
            fontSize: "20px",
          },
          {
            breakpoint: "xxl",
            fontSize: "24px",
            lineHeight: "140%",
          },
        ],
      },
      {
        name: "title06",
        fontSize: "20px",
        lineHeight: "130%",
        fontWeight: "700",
        fontFamily: "primary",
        responsive: [
          {
            breakpoint: "s",
            fontSize: "20px",
          },
          {
            breakpoint: "m",
            fontSize: "20px",
          },
          {
            breakpoint: "l",
            fontSize: "20px",
          },
          {
            breakpoint: "xxl",
            fontSize: "22px",
          },
        ],
      },
      {
        name: "subtitle01",
        fontSize: "15px",
        lineHeight: "150%",
        fontWeight: "600",
        fontFamily: "primary",
        responsive: [
          {
            breakpoint: "s",
            fontSize: "17px",
          },
          {
            breakpoint: "m",
            fontSize: "17px",
          },
          {
            breakpoint: "l",
            fontSize: "18px",
          },
          {
            breakpoint: "xxl",
            fontSize: "22px",
          },
        ],
      },
      {
        name: "quote01",
        fontSize: "21px",
        lineHeight: "130%",
        fontFamily: "primary",
        fontWeight: "600",
        responsive: [
          {
            breakpoint: "s",
            fontSize: "28px",
          },
          {
            breakpoint: "m",
            fontSize: "36px",
          },
          {
            breakpoint: "xxl",
            fontSize: "42px",
          },
        ],
      },
      {
        name: "quote02",
        fontSize: "18px",
        lineHeight: "160%",
        fontFamily: "primary",
        fontWeight: "400",
        responsive: [
          {
            breakpoint: "m",
            fontSize: "24px",
          },
          {
            breakpoint: "xl",
            fontSize: "31px",
          },
        ],
      },
      {
        name: "lead",
        fontSize: "16px",
        lineHeight: "140%",
        fontFamily: "secondary",
        fontWeight: "400",
        responsive: [
          {
            breakpoint: "xs",
            fontSize: "18px",
            lineHeight: "150%",
          },
          {
            breakpoint: "xl",
            fontSize: "20px",
            lineHeight: "160%",
          },
          {
            breakpoint: "xxl",
            fontSize: "22px",
          },
        ],
      },
      {
        name: "leadBold",
        fontSize: "18px",
        lineHeight: "150%",
        fontFamily: "secondary",
        fontWeight: "600",
        responsive: [
          {
            breakpoint: "xxl",
            fontSize: "22px",
          },
        ],
      },
      {
        name: "body",
        fontSize: "16px",
        lineHeight: "150%",
        fontFamily: "secondary",
        fontWeight: "400",
        responsive: [
          {
            breakpoint: "xl",
            fontSize: "18px",
            lineHeight: "160%",
            letterSpacing: "-.025rem",
          },
        ],
      },
      {
        name: "bodyBold",
        fontSize: "16px",
        lineHeight: "150%",
        fontFamily: "secondary",
        fontWeight: "600",
        letterSpacing: "-.025rem",
        responsive: [
          {
            breakpoint: "xl",
            fontSize: "18px",
          },
        ],
      },
      {
        name: "bodyItalic",
        fontSize: "16px",
        lineHeight: "150%",
        fontFamily: "secondary",
        fontStyle: "italic",
        fontWeight: "400",
        responsive: [
          {
            breakpoint: "xl",
            fontSize: "18px",
            lineHeight: "160%",
          },
        ],
      },
      {
        name: "bodyTight",
        fontSize: "16px",
        lineHeight: "140%",
        fontFamily: "secondary",
        fontWeight: "400",
        responsive: [
          {
            breakpoint: "xl",
            fontSize: "17px",
          },
          {
            breakpoint: "xxl",
            fontSize: "18px",
          },
        ],
      },
      {
        name: "bodyTightBold",
        fontSize: "16px",
        lineHeight: "140%",
        fontFamily: "secondary",
        fontWeight: "500",
        responsive: [
          {
            breakpoint: "xxl",
            fontSize: "18px",
          },
        ],
      },
      {
        name: "bodyAlt",
        fontSize: "16px",
        lineHeight: "140%",
        fontFamily: "primary",
        fontWeight: "400",
        responsive: [
          {
            breakpoint: "xl",
            fontSize: "18px",
          },
          {
            breakpoint: "xxl",
            fontSize: "18px",
          },
        ],
      },
      {
        name: "small",
        fontSize: "14px",
        lineHeight: "150%",
        fontFamily: "secondary",
        fontWeight: "400",
        responsive: [
          {
            breakpoint: "xl",
            fontSize: "14px",
          },
        ],
      },
      {
        name: "smallBold",
        fontSize: "14px",
        lineHeight: "140%",
        fontFamily: "secondary",
        fontWeight: "600",
        responsive: [
          {
            breakpoint: "xl",
            fontSize: "15px",
          },
        ],
      },
      {
        name: "smallTight",
        fontSize: "14px",
        lineHeight: "115%",
        fontFamily: "secondary",
        fontWeight: "400",
        responsive: [
          {
            breakpoint: "xxl",
            fontSize: "15px",
          },
        ],
      },
      {
        name: "smallTightBold",
        fontSize: "14px",
        lineHeight: "115%",
        fontFamily: "secondary",
        fontWeight: "700",
        responsive: [
          {
            breakpoint: "xl",
            fontSize: "15px",
          },
        ],
      },
      {
        name: "tiny",
        fontSize: "12px",
        lineHeight: "125%",
        fontFamily: "secondary",
        fontWeight: "400",
        responsive: [
          {
            breakpoint: "xxl",
            fontSize: "13px",
          },
        ],
      },

      {
        name: "tinyBold",
        fontSize: "14px",
        lineHeight: "125%",
        fontFamily: "secondary",
        fontWeight: "700",
        responsive: [
          {
            breakpoint: "xl",
            fontSize: "13px",
          },
        ],
      },
      {
        name: "tinyTight",
        fontSize: "13px",
        lineHeight: "110%",
        fontFamily: "secondary",
        fontWeight: "400",
        responsive: [
          {
            breakpoint: "xxl",
            fontSize: "13px",
          },
        ],
      },
      {
        name: "tinyTightBold",
        fontSize: "13px",
        lineHeight: "110%",
        fontFamily: "secondary",
        fontWeight: "600",
        responsive: [
          {
            breakpoint: "xxl",
            fontSize: "13px",
          },
        ],
      },
      {
        name: "tinyAlt",
        fontSize: "12px",
        lineHeight: "125%",
        fontFamily: "primary",
        fontWeight: "400",
        responsive: [
          {
            breakpoint: "xl",
            fontSize: "13.5px",
          },
        ],
      },
    ],
  },
  line: {
    s: "1px",
    m: "2px",
    l: "4px",
    button: "1px",
  },
  space: [
    "0px",
    "4px",
    "8px",
    "12px",
    "16px",
    "24px",
    "32px",
    "48px",
    "56px",
    "64px",
    "96px",
    "128px",
    "192px",
    "256px",
    "384px",
    "512px",
    "640px",
    "768px",
    "896px",
  ],
  negateSpace: [
    "0px",
    "-4px",
    "-8px",
    "-12px",
    "-16px",
    "-24px",
    "-32px",
    "-48px",
    "-56px",
    "-64px",
    "-96px",
    "-128px",
    "-196px",
  ],
  spacing: {
    xxxs: "4px",
    xxs: "8px",
    xs: "12px",
    s: "16px",
    m: "24px",
    l: "32px",
    xl: "48px",
    xxl: "56px",
    "3xl": "64px",
    "4xl": "80px",
    "5xl": "128px",
    button: {
      paddingX: "24px",
      paddingY: "16px",
      height: "40px",
      minWidth: "112px",
      iconSize: "16px",
    },
    input: {
      height: "40px",
      padding: "16px",
    },
  },
  shadow: {
    shadows: {
      revealedFooter: {
        x: 0,
        y: 2,
        blur: 120,
        color: "#000000",
        opacity: 0.5,
      },
    },
  },
}
