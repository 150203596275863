// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-trend-detail-js": () => import("./../../../src/components/TrendDetail.js" /* webpackChunkName: "component---src-components-trend-detail-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mision-js": () => import("./../../../src/pages/mision.js" /* webpackChunkName: "component---src-pages-mision-js" */),
  "component---src-pages-politica-privacidad-js": () => import("./../../../src/pages/politica-privacidad.js" /* webpackChunkName: "component---src-pages-politica-privacidad-js" */),
  "component---src-pages-trends-2020-js": () => import("./../../../src/pages/trends-2020.js" /* webpackChunkName: "component---src-pages-trends-2020-js" */)
}

