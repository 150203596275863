import { createGlobalStyle } from "styled-components"
import { theme } from "@sqymagma/theme"

export const GlobalStyles = createGlobalStyle`
  *{
    box-sizing: border-box;
  }
  body {
    font-family: ${theme("fontFamily.secondary")};
    line-height: ${theme("lineHeightBase")};
    color: ${theme("color.text01")};
    background-color: ${theme("color.primaryBackground")};
    min-height: 100%;
    overflow: auto;
    -webkit-font-smoothing: antialiased;
 
      &:before {
        content: "";
        width: 100vw;
        height: 100vh;
        opacity: 0;
        transition: all ease .3s;
        transition-delay: 0.5s;
        display: flex;
        position: absolute;
        z-index: 99;
        pointer-events:none

      }

    &.is-panel-open {
      max-height: 100vh;
      overflow: hidden;
   
      
      &:before {
        content: "";
        background-color: #afd1e9b3;
        opacity: 1;
        transition: all ease .3s;
        pointer-events:all
      }
    }
  }
`
